/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Drawer,
} from "@material-tailwind/react";
import { useNavigate, Link } from "react-router-dom";

/* ICONS */
import { HiBars2 } from "react-icons/hi2";
import { FaRegCircle, FaRegCircleDot } from "react-icons/fa6";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions/userAction";

/* ASSETS */
import Logo from "../../assets/images/logo.png";

/* SAMPLE DATA */
import navMenu from "../../data/navigation";

const Header = () => {
  // GLOBAL STATES
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.user);
  console.log(userDetails);

  /* GREETING */
  var greetingText = "";
  const today = new Date();
  const currentHours = today.getHours();

  if (currentHours < 12) {
    greetingText = "Good morning";
  } else if (currentHours < 18) {
    greetingText = "Good afternoon";
  } else {
    greetingText = "Good evening";
  }

  const [mobileDrawer, setMobileDrawer] = useState(false);
  var pathname = window.location.pathname;

  const handleLogout = () => {
    toast.warn("You have logged out of your account successfully.");
    navigate("/login");
    localStorage.clear();
    dispatch(actions.setUser(null));
    dispatch(actions.setToken(null));
    dispatch(actions.setLoggedIn(false));
  };

  useEffect(() => {
    if (userDetails) {
      if (!userDetails?.loggedIn) {
        toast.warn("Please login to account");
        navigate("/");
      }
    }
  }, [userDetails]);

  return (
    <section className="h-header bg-white border border-authBorder px-5 w-full rounded-lg">
      <div className="flex items-center justify-between h-full">
        {/* LEFT */}
        <h2 className="text-lg font-ibm-bold text-black hidden xl:block lg:block">
          {greetingText}, Welcome to the admin panel
        </h2>

        <Button
          className="bg-transparent shadow-none hover:shadow-none border border-[#E5E5E5] dark:border-darkBorder p-2 rounded-full hover:bg-black hover:bg-opacity-10 block xl:hidden lg:hidden"
          onClick={() => setMobileDrawer(!mobileDrawer)}
        >
          <HiBars2 className="w-5 h-5 text-black" />
        </Button>

        {/* RIGHT */}
        <div className="flex items-center">
          <Menu
            animate={{
              mount: { y: 0 },
              unmount: { y: 25 },
            }}
            allowHover
          >
            <MenuHandler>
              <div className="flex items-center gap-2 hover:outline-none cursor-pointer">
                <div className="w-10 h-10 bg-black rounded-full flex items-center justify-center">
                  <p className="text-sm font-ibm-semibold text-white leading-none">
                    {userDetails?.user?.firstName?.[0]}
                    {userDetails?.user?.lastName?.[0]}
                  </p>
                </div>
                <div>
                  <h2 className="text-base font-ibm-semibold text-black dark:text-white">
                    {userDetails?.user?.firstName} {userDetails?.user?.lastName}
                  </h2>
                  <p className="text-sm font-ibm-regular text-greyText dark:text-lightGreyText leading-none mt-1">
                    Administrator
                  </p>
                </div>
              </div>
            </MenuHandler>
            <MenuList className="p-0 mt-1 bg-white dark:bg-darkSidebar border dark:border-darkBorder">
              <Link to="/">
                <MenuItem
                  onClick={handleLogout}
                  className="hover:bg-logoYellow hover:bg-opacity-20 active:bg-buttonColor active:bg-opacity-20 focus:bg-buttonColor focus:bg-opacity-20 rounded-none py-3"
                >
                  <p className="text-sm font-ibm-regular text-[#4B4B4B] dark:text-white">
                    Logout
                  </p>
                </MenuItem>
              </Link>
            </MenuList>
          </Menu>
        </div>
      </div>

      {/* MOBILE DRAWER */}
      <Drawer
        open={mobileDrawer}
        onClose={() => setMobileDrawer(!mobileDrawer)}
        className="bg-black p-0 !h-screen"
        overlayProps={{
          className: "h-screen fixed",
        }}
      >
        {/* SIDEBAR HEADER */}
        <div className="border-b border-white border-opacity-20 p-5 flex items-center justify-between">
          <img
            src={Logo}
            className="w-20 h-auto object-contain"
            alt="Agency Website"
          />
          <Button
            className="shadow-none hover:shadow-none rounded-full p-2 bg-transparent hover:bg-white hover:bg-opacity-10"
            onClick={() => setMobileDrawer(!mobileDrawer)}
          >
            {mobileDrawer ? (
              <FaRegCircle className="w-4 h-4 text-sidebarText" />
            ) : (
              <FaRegCircleDot className="w-4 h-4 text-sidebarText" />
            )}
          </Button>
        </div>

        {/* NAVIGATION OPTIONS */}
        <div className="p-5">
          {/* DASHBOARD */}
          <div className="mt-0">
            {navMenu.map((nav) => (
              <Link
                to={nav.link}
                key={nav.id}
                className="w-full group flex items-center gap-3 first:mt-0 mt-5"
                onClick={() => setMobileDrawer(!mobileDrawer)}
              >
                <nav.icon
                  className={`w-5 h-5 text-sidebarText ${pathname ===
                    nav.link &&
                    "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
                />
                <div>
                  <p
                    className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                      nav.link &&
                      "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                  >
                    {nav.name}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Drawer>
    </section>
  );
};

export default Header;
