import { Link } from "react-router-dom";

/* SAMPLE DATA */
import navMenu from "../../data/navigation";

/* ASSETS */
import Logo from "../../assets/images/logo.png";

const Sidebar = () => {
  var pathname = window.location.pathname;

  return (
    <section className="h-screen w-sidebar bg-[#141518] transition-all duration-300 ease-linear">
      {/* HEADER */}
      <div className="border-b border-white border-opacity-20 px-5 py-3 flex items-center text-center">
        <div className="bg-white rounded-md p-2">
          <img
            src={Logo}
            className="w-20 h-auto object-contain"
            alt="Agency Website"
          />
        </div>
      </div>

      {/* NAVIGATION OPTIONS */}
      <div className="h-[80vh] flex flex-col justify-between">
        <div className="p-5">
          <div className="mt-0">
            {navMenu.map((nav) => (
              <Link
                to={nav.link}
                key={nav.id}
                className="w-full group flex items-center gap-3 first:mt-5 mt-8"
              >
                <nav.icon
                  className={`w-5 h-5 text-sidebarText ${pathname ===
                    nav.link &&
                    "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
                />
                <div>
                  <p
                    className={`text-base font-ibm-regular text-sidebarText ${pathname ===
                      nav.link &&
                      "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                  >
                    {nav.name}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
