/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import Select from "react-select";
import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteLandingPageDetailsById,
  getLandingPageList,
} from "../../../service/api";

const filterOptions = [
  { value: "All", label: "All" },
  { value: "Buy an insurance agency", label: "Buy an insurance agency" },
  {
    value: "Launch your own insurance agency",
    label: "Launch your own insurance agency",
  },
  {
    value: "Become a licensed insurance agent",
    label: "Become a licensed insurance agent",
  },
];

const LandingPage = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [landingPageList, setLandingPageList] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterValue, setFilterValue] = useState();

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getLandingPageListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getLandingPageListFunction();
  };

  const getLandingPageListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }
    if (filterValue?.value !== undefined && filterValue?.value !== "All") {
      params.interest = filterValue?.value;
    }

    getLandingPageList(params)
      .then((res) => {
        setLandingPageList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteLandingPageDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getLandingPageListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      width: "10vh",
      selector: (row, index) => (
        <span className="text-sm font-ibm-regular text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <p className="text-sm font-ibm-semibold text-black">
          {row?.firstName} {row?.lastName}
        </p>
      ),
    },
    {
      name: "Email & Phone",
      grow: 1.5,
      selector: (row) => (
        <>
          <a
            href={`mailto:${row.email}`}
            className="block text-sm font-ibm-regular text-blue-400 underline"
          >
            {row.email}
          </a>
          <a
            href={`tel:${row.phoneNumber}`}
            className="mt-1 block text-sm font-ibm-regular text-blue-400 underline"
          >
            {row.phoneNumber}
          </a>
        </>
      ),
    },
    {
      name: "Interested in",
      grow: 1.5,
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-black">
          {row?.interest === undefined ? "-" : row?.interest}
        </p>
      ),
    },
    {
      name: "Timestamp",
      selector: (row) => (
        <>
          <p className="text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="mt-1 text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </p>
        </>
      ),
    },
    {
      name: "Actions",
      grow: 0.5,
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip
            content="Delete"
            className="text-sm font-ibm-regular text-white"
          >
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2.5 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getLandingPageListFunction();
  }, [page, sizePerPage, search, filterValue]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Queries | Agency Website</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-ibm-bold text-black tracking-tight">
              Landing Page
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              This page shows the queries coming from the banner.
            </h2>
          </div>
          <div className="block xl:flex lg:flex items-center gap-2">
            <div className="w-[25vh]">
              <Select
                options={filterOptions}
                className="react-select"
                classNamePrefix="dropdown"
                placeholder="Filter by"
                value={filterValue}
                onChange={(e) => setFilterValue(e)}
              />
            </div>

            {/* SEARCHBAR */}
            <div className="w-full xl:w-[25vh] lg:w-[25vh] mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? landingPageList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>

        {/* DELETE MODAL */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete this entry
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete these details?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Confirm
              </Button>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default LandingPage;
