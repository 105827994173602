/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

const LoadingButton = (props) => {
  return (
    <Button
      type={props.type}
      onClick={props.onClick}
      disabled={props.loading}
      className="w-full mt-5 rounded-lg font-ibm-semibold text-white bg-logoYellow hover:bg-opacity-80 px-5 py-3 text-base shadow-none hover:shadow-none normal-case transition-all duration-300 ease-in-out"
    >
      {props.loading ? "Please wait.." : props.label}
    </Button>
  );
};

export default LoadingButton;
