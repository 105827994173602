/* eslint-disable no-unused-vars */
/* ICONS */
import { IoHeadsetOutline } from "react-icons/io5";

const navMenu = [
  {
    id: 1,
    link: "/landing-page",
    icon: IoHeadsetOutline,
    name: "Landing Page",
  },
];

export default navMenu;
